@import "styles/colors.scss";
@import "styles/mixins";

.checkoutPayment {
  .card {
    padding: 40px;
    background-color: $lighter-gray;
  }

  .creditCardDisplay {
    padding: 10px 70px;
    border: 0.5px solid $light-gray;
    width: fit-content;
    background-color: $white;
  }

  .stars {
    margin: 0px;

    span {
      vertical-align: sub;
    }
  }
}

@include small {
  .checkoutPayment {
    height: 100vh;

    .card {
      margin-top: 10px;
    }
  }
}
