@import 'styles/colors';
@import 'styles/mixins';

.storeConnectContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  //background-color: $light-gray;
  margin-top: 16px;
  padding: 20px;
}
