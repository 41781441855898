@import "../../styles/colors.scss";


.planCard {
  border: 1px solid #dddddd;
  background-image: transparent;
  min-width: 305px;
  height: 330px;
  position: relative;


  &Centered {
    background-image: -ms-linear-gradient(-140deg, #246071 0, #062832 100%);
    background-image: -webkit-linear-gradient(-140deg, #246071 0, #062832 100%);

    min-width: 305px;
    height: 330px;
    position: relative;

    .centerText {
      padding-top: 40px;
    }

    .cardContent {
      color: $white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    .actionContainer {
      justify-content: center;
      padding-bottom: 20px;
      padding-top: 25px;
      position: absolute;
      bottom: 20px;
      width: 100%;
    }

    ul {
      text-align: left;
     
      padding-top: 5px;
    }
    
    li {
      color: $white;
      font-size: 12px;
    }

  }

  .cardContent {
    color: $blue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .actionContainer {
    justify-content: center;
    padding-bottom: 0px;
    padding-top: 25px;
    position: absolute;
    bottom: 20px;
    width: 100%;
  }

  ul {
    text-align: left;
    padding-top: 21px;
 
  }
  
  li {
    color: $blue;
    font-size: 12px;
  }
}

.text {
  &Price {
    padding-top: 24px;
    padding-bottom: 12px;
  }
}

.planCard:hover {
  background: -ms-linear-gradient(-140deg, #246071 0, #062832 100%);
  background: -webkit-linear-gradient(-140deg, #246071 0, #062832 100%);

  .cardContent {
    color: $white;
  }

  li {
    color: $white;
    font-size: 12px;
  }
}

.planDetails{
  display: flex;
  justify-content: center;
  ul{
    padding-left: 20px;
  }
}

.isVIP{

  li:first-child{
    font-weight: bold;
  }
}