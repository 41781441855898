@import "./styles/mixins";
@import "./styles/colors";
@import "./styles/text";
@import "./styles/utils";
@import "./styles/icons";
@import "./styles/forms";
@import "./styles/table";
@import "./styles/sidebar";

//templates
@import "./styles/templates/brandPlateView";
@import "./styles/templates/profile";
@import "./styles/templates/profile-store";
@import "./styles/templates/profile-billing";
@import "./styles/templates/profile-billing-update";
@import "./styles/templates/profile-password";
@import "./styles/templates/checkout-shipping";
@import "./styles/templates/password-reset-view";

//modals
@import "./styles/modals/modal-dunning";

//components
@import "./styles/components/order-card";
@import "./styles/components/notifications";
@import "./styles/components/breadcrumbs";
@import "./styles/components/cart-totals";
@import "./styles/components/product-inventory-modal";
@import "./styles/components/checkout-discount-form";

.App {
}

@include small {
  .App {
    margin-top: 80px;
  }
}
