@import 'styles/colors.scss';

@font-face {
  font-family: 'Georgia';
  src: url('../assets/fonts/Georgia.ttf')  format('truetype'),
}
@font-face {
  font-family: 'Georgia';
  font-weight: bold;
  src: url('../assets/fonts/Georgia-Bold.ttf')  format('truetype'),
}


$nunito: 'Nunito Sans', sans-serif;
$georgia: "Georgia" , sans-serif;


h1,h2,h3,h4,h5,h6{
    font-family: 'Georgia';
    font-weight: 700 !important;    
}

h1,h2,h3, h4,h5,h6{
    letter-spacing: 0px !important;
}

p, li{
    color: $gray;
    font-family: 'Nunito Sans', sans-serif !important;
}

a{
  color: $gray;
  text-decoration: underline;
}

.text {



  &__page-header {
    text-transform: uppercase;
    letter-spacing: 4.8px;
    font-size: 12px;
    color: $gray;
    font-weight: 400;
  }

  &--fs-12 {
    font-size: 12px;
  }

  &--align-left {
    text-align: left;
  }
 
  &--italic {
    font-style: italic;
  }

  &--no-underline{
    text-decoration: none;
  }

  &--nunito {
    font-family: 'Nunito Sans', sans-serif !important;
  }

  &--georgia {
    font-family: "Georgia" , sans-serif !important;
  }

  &--primary{
    color: $peach;
  }

  &--spacing-0 {
    letter-spacing: 0px !important;
  }

  &--spacing-0-2 {
    letter-spacing: 0.2px !important;
  }

  &--spacing-2 {
    letter-spacing: 2px !important;  
  }

  &--spacing-2-4 {
    letter-spacing: 2.4px !important;  
  }


  &--spacing-6 {
    letter-spacing: 6px !important;
  }

  &--align-center {
    text-align: center;
  }

  &--spacing-5 {
    letter-spacing: 5px !important;
  }

  &--spacing-4 {
    letter-spacing: 4px !important;
  }


  &--spacing-1 {
    letter-spacing: 1.6px !important;
  }

  &--spacing-48 {
    letter-spacing: 4.8px !important;
  }

  &--upper {
    text-transform: uppercase;
  }
  
  &--secondary{
    color: $blue;
  }
  &--success{
    color: $success;
  }
  &--error{
    color: $error;
  }
  &--info{
    color: $gray !important;
  }
  
  &--warning{
    color: $orange;
  }
  &--blue {
    color: $blue;
  }
  &--peach {
    color: $peach !important;
  }

  &--dark-blue {
    color: $dark-blue;
  }

  &--white {
    color: $white !important
  }

  &--medium-gray {
    color: $medium-gray;
  }

  &--gray {
    color: $gray;
  }
  &--light-gray {
    color: $light-gray;
  }

  &--regular {
    font-weight: 400 !important;
  }

  &--bold {
    font-weight: bold !important;
  }

}


.text-mobile{

  &--18{
    font-size: 18px;
  }
}