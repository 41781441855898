.totalsContainer {
    width: 90%;
    padding-left: 30px;
    padding-right: 30px;
    text-transform: uppercase;

    .costContentContainer {
        display: flex;
        padding-top: 5px;
        min-width: 200px;
    
        &Subtotal {
          padding-top: 0px;
        }
    
        &Total {
          padding-top: 20px;
          border-top: 1px solid #CCCCCC;
        }
    
        &ProcessingFee {
          padding-bottom: 20px;
        }
    }

    .text {
        &TotalNumber {
          margin-left: auto;
        }
    }
}

.poweredByContainer{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.poweredByStripe{
  width: 100px;
  margin-top: 50px;
  margin-left: auto;
}