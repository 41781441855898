@import "styles/colors.scss";
@import "styles/mixins.scss";

.button {
  position: relative;

  box-shadow: none;
  transition: background-color 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Nunito Sans", sans-serif !important;
  border-radius: 4px;

  box-shadow: none !important;
  padding: 10px 30px !important;
  letter-spacing: 6.03172px !important;

  .btnLabel {
    font-weight: 700;
    line-height: 1;
  }

  &:hover {
    box-shadow: none;
    box-shadow: none !important;
  }
}

.hidden {
  visibility: hidden;
}

.buttonIconWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

/*
GLOABL BUTTON STYLING
*/
:global(.MuiButton-root) {
  padding: 10px 30px;
  font-weight: 700;
  letter-spacing: 6.03172px;
  box-shadow: none;
}

:global(.MuiButton-root:hover) {
  background-color: initial;
}

:global(.MuiButton-root.Mui-disabled) {
  border: none !important;
  background: $light-gray !important;
  color: $white !important;
}

:global(.MuiButton-root.MuiButton-outlinedPrimary:hover) {
  background: $peach;
  color: $white;
  border: 1px solid $peach;
}

:global(.MuiButton-root.MuiButton-containedPrimary) {
  border: 1px solid $peach;
}

:global(.MuiButton-root.MuiButton-containedSecondary) {
  border: 1px solid $blue;
}

:global(.MuiButton-root.MuiButton-containedSecondary:hover) {
  background-color: $white !important;
  color: $blue;
  border: 1px solid $blue;
}

:global(.MuiButton-root.MuiButton-containedPrimary:hover) {
  background: $white;
  color: $peach;
  border: 1px solid $peach;
}

@include small {
  // .button {
  //   padding: 10px 20px !important;
  //   .btnLabel {
  //     display: flex;
  //     flex-direction: row;
  //     align-items: flex-start;
  //     padding: 0px;
  //     gap: 10px;

  //     width: 262px;
  //     height: 20px;
  //   }

  //   //   svg {
  //   //     display: none;
  //   //   }
  // }
}
