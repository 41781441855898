@import 'styles/mixins';

.onboardingWrapper {
  display: flex;
  background-image: url('/assets/images/onboarding_background.jpg');
  width: 100vw;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;

  @include small {
    flex-direction: column;
    align-items: center;   
  }

  .welcomeContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 20px;

    @include small {
      flex-direction: row;
      align-items: center;
      padding-bottom: 5px;
    }
  }

  .blankaLogo {
    width: 150px;
    height: auto;
    padding: 10px;
    margin-left: 2px;

    @include small {
      width: 92px;
      margin-top: -10px;
    }
  }

  .card {
    background: #fff;
    width: 80%;
    max-width: 1000px;
    height: fit-content;
    padding: 40px;
    border-radius: 0px;

    @include small {
      padding: 20px 0px;

      width: 100%;
      // height: 300vh;
    }
  }

  .modalContent {
    display: flex;
    flex-direction: column;

    @include small {
      padding: 10px;
    
    }
  }
}