@import 'styles/colors.scss';

form{

    .MuiInputLabel-root{
        top: -8px;
        left: -15px;
        font-size: 14px !important;
        text-transform: uppercase;
        letter-spacing: 2.00094px;
        font-family: 'Nunito Sans',sans-serif !important;
    }

    .MuiOutlinedInput-input {
        border: 1px solid $gray;
        padding: 16.5px 14px !important;
    }

    .MuiFormControl-root {
        width: 100%;
    }

    .Mui-focused input {
        border: none;
        border: 1px solid #d46e5a;
    }

    .MuiOutlinedInput-notchedOutline{
        display: none !important;
    }
    
    .MuiOutlinedInput-root{
        border-radius: 0px;
    }

    input {
        font-family: 'Nunito Sans',sans-serif !important;
    }
}

.MuiInputLabel-formControl {
    color: $gray !important;
}

.MuiAutocomplete-inputRoot {
    padding: 0px !important;
    font-family: 'Nunito Sans',sans-serif !important;
    width: 150px !important;
}

.MuiCheckbox-root {
    padding: 0px 10px 0px 0px !important;
}

.MuiFormControlLabel-root {
    margin-left: 0px !important;
    .MuiFormControlLabel-label {
        text-transform: uppercase;
        letter-spacing: 1.6px;
        font-size: 8px;
        line-height: 15px;
        color: $gray;
        font-family: 'Nunito Sans',sans-serif !important;
    }
}

.MuiLink-root {
    text-decoration-color: $gray !important;
    cursor: pointer;
}


#shipping-form{
    margin-top: 25px;
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .doubleLine{
        margin-bottom: 25px;
        width: 100%;
        display: flex;
        align-items: stretch;
        input, .MuiFormControl-root{
            width: auto;
            flex: 1 1 0px;
        }

        .MuiFormControl-root:first-of-type{
            margin-right: 10px;
        }

    }


    
    .tripleLine{
        margin-bottom: 25px;
        display: flex;
        align-items: stretch;
        width: 100%;
        input, select, .MuiFormControl-root{
            width: auto;

            flex: 1 1 0px
        }



        .MuiFormControl-root:first-of-type{
            margin-right: 10px;
        }
        .MuiFormControl-root:nth-of-type(2){
            margin-right: 10px;
        }

    }


    .singleLine{
        margin-bottom: 25px;
        width: 100%; 
        input{
            width: 100%;
        }
    
        .MuiTextField-root{
            width: 100%;
        }
    }

    .button--red-outline{
        right: 0px;
        margin-top: 25px;
    }

    .MuiAutocomplete-root{
        width: 250px;
        input, .MuiFormControl-root, .MuiOutlinedInput-root{
            width: 250px !important;
        }
        .MuiInputLabel-root{
            
        }
    }

}

input {
    font-family: 'Nunito Sans',sans-serif !important;
}