@import "styles/colors.scss";
@import "styles/mixins";

.appBar {
  background: $white;
  .text {
    &Link {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }

    &BlogLink {
      font-size: 12px;
      color: $gray;

      &:hover {
        text-decoration: none;
      }

      @include small {
        display: none;
      }
    }
  }

  .bellIcon {
    top: -7px;
    right: 7px;
  }

  .accountLinks {
    display: flex;
    justify-content: center;
    align-items: center;

    padding-left: 10px;

    a {
      font-size: 12px;
      font-weight: 400;
      color: $gray;

      &:hover {
        text-decoration: underline;
      }

      &:first-child {
        padding-right: 10px;
        border-right: 1px solid $gray;
      }
      &:last-child {
        padding-left: 10px;
      }
    }
  }
}

.cartIconWrapper {
  display: flex;
  text-decoration: none;

  span {
    font-size: 12px;
    margin-top: -3px;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
