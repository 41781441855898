.onboardingStepThree {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.upsellContainer {
  display: flex;
  text-align: center;
  flex-direction: column;
  text-align: center;
}

.stripeContainer {
  display: flex;
  justify-content: center;
  padding-top: 35px;
  text-align: center;
  
}

.stripeContainer form{
  width: 100%;
  max-width: 500px;

}

.submitButton{
  margin-top: 20px;
}

.loading{
  padding: 75px 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.poweredBy{
  width: 120px;
  
}

.poweredByContainer{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  a{
    margin-bottom: 5px;
    font-size: 9px;
  }
}