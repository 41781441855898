@import "styles/colors";
@import "styles/mixins";

.templateLogin {
  display: flex;
  background-image: url("../../assets/images/onboarding_background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  min-height: 100vh;
  align-items: center;
  justify-content: center;

  .card {
    padding: 70px;
    display: flex;
    width: 100vh;
    flex-direction: column;
    justify-content: center;
    max-width: 800px;

    @include medium-down {
      padding: 20px;
      padding-bottom: 80px;
    }
  }

  .textRed {
    color: $peach;
  }

  .termsConditionsContainer {
    display: flex;

    .termsText {
      font-size: 10px;
      color: $gray;
      padding-top: 2px;
    }
  }

  .blankaLogo {
    width: 150px;
    height: auto;
    padding: 10px;
    margin-left: 2px;
  }

  .welcomeContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 20px;

    @include medium-down {
      flex-direction: column;
      align-items: center;
    }
  }

  .headerTextContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: fit-content;
    margin: 0 auto;
  }

  .resetPasswordContainer {
    display: flex;
    flex-direction: row;

    align-items: center;

    padding-top: 5px;
  }

  .formContainer {
    display: flex;
    justify-content: center;
    padding-top: 35px;

    .form {
      display: flex;
      flex-direction: column;
    }
  }

  .controllerContainer {
    width: 100%;
    padding-top: 30px;
  }

  .text {
    &Link {
      font-size: 12px;
      cursor: pointer;
      text-decoration: none;
      text-align: center;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }
    &Reset {
      font-size: 12px;
    }
    &Notice {
      max-width: 300px;
      padding-top: 45px;
    }

    &Error {
      margin: 0 auto;
      position: absolute;
      left: 0px;
      bottom: -40px;
    }
  }
}

.MuiOutlinedInput-root {
  border-radius: 0px;
}

.loginButtonWrapper {
  position: relative;
  width: 100%;
  margin-top: 0px;

  .MuiButton-root {
    width: 100%;
  }
}

.tokenBox {
  margin-top: 25px;
  min-width: 250px;
  margin: 25px auto;
}

.loginContainer {
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: center;
  margin-top: 25px;
}

.recentLogins {
  margin-right: 50px;
}

.ssoContainer {
  display: flex;
  justify-content: space-between;
  padding: 32px 0px;
  max-width: 450px;
  width: 100%;
  margin-bottom: 44px;
  margin: 0 auto;
}

.dividerWrapper {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.divider {
  color: #ccc;
  width: 450px;
  height: fit-content;
}

.messageShopfy {
  width: 355px;
  text-align: justify;
}

@include small {
  .ssoContainer {
    flex-direction: column;
    max-width: 355px;

    .ssoBtn {
      max-width: 355px;
      width: 100%;
    }
  }

  .dividerWrapper {
    width: 100%;
  }

  .divider {
    width: 100%;
    margin: 0 auto;
  }

  .templateLogin {
    margin-top: -90px;
    .formContainer,
    .messageShopfy {
      max-width: 355px;
      width: 100%;
      margin-top: 0px;

      .form {
        width: 100%;
      }
    }

    .resetPasswordContainer {
      width: 100%;
      justify-content: flex-start;
    }
  }
}
