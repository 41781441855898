@import 'styles/colors.scss';



.active{
    font-weight: bold;
}


:global( .MuiStepLabel-label.Mui-active){
    font-weight: bold !important;
  }