.submitButton{
  margin-top: 20px;

}

.buttonContainer{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loadingContainer{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}