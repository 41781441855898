.onboardingStepFive {
  display: flex;
  text-align: center;
  flex-direction: column;
}

.connectedMessageContainer {
  padding-bottom: 35px;
}

.text {
  &Domain {
    font-weight: 300;
    padding-right: 10px;
  }
}

.storeNameContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;

  img {
    width: 25px;
    height: 25px;
    padding-right: 10px;

  }
}

.actionContainer {
  padding-top: 50px;
}


.MuiSvgIcon-root {
  color: #00AA51 !important;
}