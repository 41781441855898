@import "../../styles/colors.scss";

.planCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  flex-grow: 1;
  width: fit-content;
  height: 500px;
  border-radius: 4px;
  box-shadow: none;

  .cardContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 280px;
  }

  .planDetails {
    width: 100%;
  }

  ul {
    list-style: none;
    padding: 0;
    width: 100%;
  }

  li {
    display: flex;
    color: $blue;
    width: 100%;
    justify-content: center;
    margin: 8.25px 0;
    font-size: 14px;
    border-bottom: 1px solid $light-gray;
    height: 40px;
    margin: 0px;
    display: flex;
    align-items: center;

    svg {
      height: 20px;
      width: 20px;
    }
  }

  li:last-child {
    border: none;
  }

  .actionContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .textPrice {
    font-size: 13px;
    color: $blue;
    letter-spacing: normal;
    margin-top: 0.4rem;
  }

  .planType {
    color: $peach;
    font-family: "Georgia", sans serif;
    font-weight: 700;
    text-transform: uppercase;
    color: $peach;
    font-size: 24px;
    margin-top: 20px;
    text-align: center;
  }

  .scaleCardBtn {
    margin-top: -40px;
    padding-top: 46px;
  }

  &:hover {
    cursor: pointer;
    background: linear-gradient(270deg, #ffffff 0%, #efefef 100%);
    // border-left: 1px solid #ccc;
    // border-right: 1px solid #ccc;
    // border-radius: 0;
  }
}

.cta {
  align-items: center;
  letter-spacing: 0px !important;
  padding: 5px 15px !important;
  margin-top: 50px !important;

  height: 40px;
  width: 165px;
  font-size: 11px;
  border-radius: 2px;
  line-height: 1.6;

  svg {
    height: 20px;
    width: 20px;
  }
}

.scaleCard {
  border: 3px solid #d56f5b;
  margin-top: -40px;
  margin-bottom: -16px;
  padding: 0;
  padding-top: 40px;

  &:hover {
    cursor: pointer;
    border: 3px solid #d56f5b;
  }

  li {
    height: 40px;
  }

  li:first-child {
    height: 41px;
  }
}

.featureColumn {
  display: flex;
  width: 210px;
  font-family: "Nunito Sans";
  color: $gray;
  font-size: 14px;
  justify-content: flex-start;
  box-shadow: none;

  &:hover {
    cursor: default;
    background: none;
    border: none;
  }

  ul {
    list-style: none;
    padding-top: 31.5px;
  }

  li {
    width: 100%;
    padding-right: 25px;
    margin-bottom: 0px;
    border-bottom: 1px solid $light-gray;
    display: flex;
    align-items: center;
    justify-content: start;
  }

  li:last-child {
    border: none;
  }
}
