@import 'styles/colors.scss';

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 6px 0;
  background-color: $blue;

  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: $white;
  font-size: 7px;
  text-align: center;
  letter-spacing: 1.5px;
  font-weight: 600;
  text-transform: uppercase !important;
  font-family: 'Nunito Sans', sans-serif !important;
}

/* top right*/
.ribbonTopRight {
  top: -10px;
  right: -10px;
}
.ribbonTopRight::before {
  top: 0;
  left: 0;
}
.ribbonTopRight::after {
  bottom: 0;
  right: 0;
}
.ribbonTopRight span {
  left: -4px;
  top: 33px;
  transform: rotate(45deg);
}