@import "styles/mixins.scss";

.main {
  margin-left: 220px;
  margin-top: 32px;
  padding-top: 87px;
  padding-bottom: 100px;
  min-width: 1000px;
}

.mainClosed {
  margin-left: 62px;
  margin-top: 64px;
  padding-top: 100px;
}

@include small {
  .main {
    margin-left: 0px;
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    min-width: 100vw;
    width: 100%;
  }

  .mainClosed {
    margin-left: 0px;
    margin-top: 0px;
    padding-top: 0px;
  }
}
