@import "styles/colors";

.colorSwatch {
  width: 50px;
  height: 50px;
}

.productImage {
  max-width: 80px;
  img {
    width: 80px;
    border: 1px solid $light-gray;
  }
}
