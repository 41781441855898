@import 'styles/colors.scss';



.navItem{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding-right: 10px;
    padding-left: 10px;
    text-decoration: none;
    letter-spacing: 4.80225px;
    text-transform: uppercase;
    color: $gray;
}


.navItemFirst{
    composes: navItem;
    padding-left: 0px;
}



.navItemFirstActive{
    composes: navItemFirst;
    font-weight: bold;
}


.navItemActive{
    composes: navItem;
    font-weight: bold;
}