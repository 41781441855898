@import "styles/colors.scss";
@import "styles/mixins";

.cartShipping {
  .card {
    padding: 40px;
    background-color: $lighter-gray;
  }

  .shippingDetailsForm {
    padding-top: 20px;
    width: 1050px;
    display: flex;

    .largeInputContainer {
      width: 470px;
      margin-top: 30px;
    }

    .checkboxContainer {
      width: 470px;
      margin-top: 15px;
    }

    .fullNameContainer {
      width: 230px;
      &Name {
        width: 230px;
        padding-right: 10px;
      }
    }

    .form {
      display: flex;
      flex-wrap: wrap;
      width: 90%;
    }

    .addressDetailsContainer {
      margin-top: 30px;
      width: 150px;
    }

    .continueToShippingButton {
      float: right;
    }
  }

  .actionContainer {
    display: flex;
    padding-top: 10px;
    justify-content: space-between;
  }

  .addressDetailsContainer {
    //margin-top: 30px;
    width: 150px;
  }

  .label {
    margin-bottom: 10px;
    span {
      font-size: 12px !important;
    }
  }
}

@include small {
  .cartShipping {
    height: 100vh;

    .card {
      margin-top: 10px;
    }
  }
}
