@import 'styles/colors.scss';


.headerBar{
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $blue;
    z-index: 9999;
    position:relative;
    top: 0px;
    color: $white;
    font-size: 12px;
}

.littleText{
    font-weight: 400;
    letter-spacing: 4.8px;
    
}

.boldText{
    font-weight: 800;
    letter-spacing: 4.8px;
}