@import 'styles/colors.scss';

.tag {
  padding: 0px 10px;
  height: 22px;
  color: #FFF;
  text-transform: uppercase;
  font-weight: 500;
  min-width: 105px;
  max-width: 120px;
  position: relative;
  font-size: 6px;
  letter-spacing: 2.4px;
  line-height: 22px;
  text-align: center;
  font-family: 'Nunito Sans', sans-serif !important;
}

.tagBlanka {
  background: $gray;
}

.tagCustom {
  background: rgba($peach, 0.8);
}

.tagUnbranded {
  background: #999999;
}

.premiumStar{
  position:absolute;
  right: -22px;
  top: 0px;
  z-index: 999;
  background-color: $blue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;

  svg{
    height: 12px;
    width: 12px;
    color: $white;
  }
}