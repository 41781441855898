@import "styles/colors.scss";
@import "styles/mixins.scss";

.ConfirmUnbrandedOrderModal {
  max-width: 400px;

  background-color: $peach !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 90%;

  .contentContainer {
    max-width: 390px;
    height: 363px;
    display: flex;
    text-align: left;
    flex-direction: column;
    justify-content: center;
  }

  .buttonContainer {
    align-self: center;
  }

  .textSpacing {
    margin: 0px 0px;
    padding-bottom: 30px;
    font-size: 12px;
  }

  .text {
    &Header {
      letter-spacing: 6.03px;
      padding-bottom: 22px;
      font-weight: bold;
    }

    &Warning {
      font-weight: 700;
      color: $white;
      margin: 0px;
      font-size: 12px;
    }

    &Content {
      font-size: 12px;
      color: $white;
      font-weight: 400;
      margin-top: 0px;
      padding-bottom: 26px;
    }
  }
}

.isPeach {
  background-color: $peach;

  p {
    color: $white !important;
  }
}

@include small {
  .ConfirmUnbrandedOrderModal {
  }
}
