$xsmall: 0px;
$small: 600px;
$medium: 960px;
$large: 1280px;
$xlarge: 1920px;



@mixin small {
  @media (min-width: #{$xsmall}) and (max-width: #{$small}) {
    @content;
  }
}

@mixin medium {
  @media (min-width: #{$small}) and (max-width: #{$medium}) {
    @content;
  }
}

@mixin medium-down {
  @media (max-width: #{$medium}) {
    @content;
  }
}


@mixin large {
  @media (min-width: #{$medium}) and (max-width: #{$large}) {
    @content;
  }
}


@mixin small-up {
  @media (min-width: #{$small}) {
    @content;
  }
}


@mixin medium-up {
  @media (min-width: #{$medium}) {
    @content;
  }
}


@mixin large-down {
  @media (max-width: #{$large}) {
    @content;
  }
}


@mixin large-up {
  @media (min-width: #{$large}) {
    @content;
  }
}