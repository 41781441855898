@import 'styles/colors.scss';

.passwordStrengthBar {
    position: relative;
    margin-bottom: 5px;
}

.labelText{
    position:absolute ;
    top: -20px;
    right: 0px;
    margin-top: 0px !important;
}