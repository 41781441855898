

$peach: #d56f5b;
$white: #FFF;
$gray: #5e5e5e;
$medium-gray: #979797;
$light-gray:#CCCCCC;
$lighter-gray:  #FCFCFC;

$blue: #246071;
$dark-blue: #284b54;
$error: #AB0000;
$dark-gray: #999999;

$orange: #FF9000;
$green:  #00AA51;

$success: #00AA51;


$box-shadow: 0px 4px 4.7px rgba(0, 0, 0, 0.3);
