@import "styles/colors.scss";
@import "styles/mixins";

.pageHeaderContainer {
  border-bottom: 1px solid $gray;
  padding-bottom: 10px;

  .actionContainer {
    margin-bottom: 0px;
  }
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  align-items: flex-start;
}

@include small {
  .pageHeaderContainer {
    flex-direction: column-reverse;
    border: none;
    margin-bottom: 0px;
    padding-bottom: 0px;
    width: 100vw;
    padding: 0 16px;
    margin-left: -16px;
    align-content: center;

    // position: sticky;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
    top: 25px;
    height: fit-content;
    background: #fff;
    z-index: 100;

    .actionContainer {
      width: 100%;
    }
  }

  .minimumnPageHeaderContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    border: none;
    width: 100vw;
    height: 60px;
    padding: 0 16px;
    margin-left: -16px;
    align-items: center;
    justify-content: center;
    // margin-bottom: 0px;
    // padding-top: 15px;
    height: max-content;
    min-height: 60px;
    // position: sticky;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
    top: 80px;
    background: #fff;
    z-index: 100;

    .actionContainer {
      width: 100%;
    }
  }

  .dropShadow {
    box-shadow: 0px 4px 4.7px rgba(0, 0, 0, 0.3);
  }
}
