.loadingScreen{
    position: fixed;
    width: 100%;    
    z-index: 9999999;
    background: #FFF;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 100vh;
    .MuiLinearProgress-root{
      position: relative !important;
    }
  }