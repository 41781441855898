@import 'styles/mixins';

.sidebar{



  .MuiDrawer-root .MuiPaper-root{
    box-shadow: 0px 0px 4.7022px rgba(0, 0, 0, 0.3);
    border-right: none;
    z-index: 1201;
    position: fixed;
    
  }


}


.navItem.activeItem {
  .icon--normal{
    display: none;
  }   
  .icon--hover{
      display: inline-block;
  }
  .material-icons {
      color: rgba(230,136,128,0.9);
  }

  .MuiListItemText-root span {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 5px;
      background: rgba(230,136,128,0.5);
      left: 0px;
      bottom: 2px;
    }
  }
}




.navItem {

  .MuiListItem-root {
    padding-left: 10px;
    justify-content: center;
    align-items: flex-start;
  }

  .MuiSvgIcon-root {
    font-size: 1.3rem;
  }

  .icon--normal{
    display: inline-block;
  }

  .icon--hover{
    display: none;
  }

  &:hover{
    .MuiListItemText-root span {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 5px;
        background: rgba(230,136,128,0.5);
        left: 0px;
        bottom: 2px;
      }
    }

    .icon--normal{
      display: none;
    }

    .icon--hover{
      display: inline-block;
    }
  }

  .MuiListItemText-root {
    width: 151px;
    span {
      width: fit-content;
    }

    @include small{
      //display: none ;
    }
  }
}

/*
.MuiDrawer-docked{
  .MuiDrawer-paperAnchorDockedLeft{


    .MuiListItem-root{

      padding-left: 40px;
   

    }
    .MuiListItemIcon-root {
      min-width: 50px;
    }
  }
}
*/
