
.form{
    max-width: 350px;
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    input{
        width: 100%;

    }
    label{
        top: -12px;
    }
   
}

.formContainer{
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.passwordStrength{
    font-size: 9px;
}

.errorMessage{
    max-width: 400px;
    text-align: center;
}

.success{
    display: flex;
    justify-content: center;
    align-items: center;
}

.passwordStrengthBar{
    margin-bottom: 0px;
}
