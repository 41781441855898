@import 'styles/mixins';

.container{
    margin-top: 50px;
    max-width: 600px;
    //min-width: 400px;

}

.loading{
    max-width: 500px;
    margin: 25px auto;
    margin-top: 25px;
}

.form{
    min-width: 500px;

    @include small{
        min-width: 250px;
    }
}