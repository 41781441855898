

.container{
    text-align: left;
    margin-top: 20px;
    :global(.StripeElement){
        padding: 0px;
    }
}

.loading{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}