@import "styles/colors.scss";
@import "styles/mixins";

.planCardMobile {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  border-radius: 0px;
  border: 0.5px solid $light-gray;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%);

  .cardContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: fit-content;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    font-size: 14px;
    color: $blue;

    display: grid;
    grid-template-columns: 90px 1fr;
    padding: 5px;

    *:first-child {
      width: 62px;
      text-align: center;
      margin-right: 15px;
    }

    *:last-child {
      text-align: start;
    }
  }

  .actionContainer {
  }

  .textPrice {
    font-size: 13px;
    color: $blue;
    letter-spacing: normal;
    margin-bottom: 1rem;
  }

  .planType {
    color: $peach;
    font-family: "Georgia", sans serif;
    font-weight: 700;
    text-transform: uppercase;
    color: $peach;
    font-size: 24px;
    margin-top: 1.2rem;
    text-align: center;
  }

  .isVIP {
    background-color: #d56f5b;
  }

  &:hover {
  }
}

.cta {
  align-items: center;
  letter-spacing: 0px !important;
  padding: 5px 15px !important;

  svg {
    height: 20px;
    width: 20px;
  }
}
