@import "styles/colors.scss";
@import "styles/mixins";

.sidebarMobile {
  position: relative;
  display: flex;
  width: 100%;

  .appBarWrapper {
    border-bottom: 1px solid rgba($peach, 0.1);
    box-shadow: none;
  }

  .toolbarWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: "80px";
  }

  .blankaLogo {
    width: 110px;
    height: 28px;
    text-align: center;
  }

  .cartIconWrapper {
    display: flex;
    text-decoration: none;

    span {
      font-size: 12px;
      margin-top: -3px;
    }
  }

  .draweHeaderWrapper {
    display: flex;
    width: 380px;
    justify-content: space-between;

    .accountLinks {
      display: flex;
      justify-content: center;
      align-items: center;

      // padding-left: 10px;

      a {
        font-size: 12px;
        font-weight: 400;
        color: $gray;

        &:hover {
          text-decoration: underline;
        }

        &:first-child {
          padding-right: 10px;
          border-right: 1px solid $gray;
        }
        &:last-child {
          padding-left: 10px;
        }
      }
    }
  }
}

.submenu {
  color: $gray;
  margin-left: 6px;

  .navItem.activeItem .MuiListItemText-root span::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 5px;
    left: 0px;
    bottom: 3px;
  }
}

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 18px;
  gap: 10px;

  position: absolute;
  width: 345px;
  height: 40px;
  left: 15px;
  top: 732px;

  background: #d46e5a;
  border-radius: 2px;

  & > * {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.096em;
    text-transform: uppercase;
  }
}
