@import "styles/mixins";

.upgradeBlock {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 15px;
  left: 15px;
  padding: 10px 15px;
  max-width: 154px;
  height: 143px;
  background: linear-gradient(63.36deg, #246071 16.7%, #143f4b 83.3%);
  white-space: normal;

  h3 {
    white-space: normal;
  }

  //   @include medium-down {
  //     z-index: 100;
  //     max-width: 345px;
  //     width: 100%;
  //     height: 95px;
  //     flex-direction: row;
  //   }
}

.text {
  text-align: center;
}
.content {
  padding: 0px;
}

.actions {
  padding: 0px;
}

.button {
  padding: 10px !important;
  margin-top: 20px;
  .btnLabel {
    padding: 5px;
  }
}

@include small {
  .upgradeBlock {
    display: flex;
    flex-direction: row;
    max-width: 345px;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: space-between;

    h3 {
      text-align: left;
    }
  }
  .button {
    padding: 7px 13px !important;
    margin-top: 0px;

    .btnLabel {
      white-space: nowrap !important;
    }
  }

  .text {
    font-family: "Georgia";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    justify-content: space-between;
  }

  .content {
    width: 50%;
  }
}
