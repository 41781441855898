@import "styles/colors.scss";
@import "styles/mixins";

.cartView {
  .card {
    background-color: $lighter-gray;
    border: 0.5px solid $medium-gray;
    border-radius: 0px;

    table {
      border: none;
    }
  }

  .actionContainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
  }

  .footerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@include small {
  .cartView {
    height: 100vh;

    .card {
      margin-top: 10px;
    }
  }
}
