@import "styles/colors.scss";
@import "styles/mixins";

.priceTable {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: fit-content;
  border: 1px solid #cccccc;
  align-items: flex-end;
  border-radius: 4px;
  padding: 0 20px;

  .tableDivider {
    border: 1px solid #cccccc;
    margin: 80px 0px;
    height: 300px;
  }
}

.priceTableMobile {
  padding-top: 750px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: fit-content;
}
