@import "styles/colors.scss";
@import "styles/mixins.scss";

:global(.MuiModal-root) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalWrapper {
  &:focus {
    outline: none;
  }
  position: relative;
  z-index: 10;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 50px auto;
  width: fit-content;

  padding: 40px;
  max-width: 1000px;
  //width: 100%;
  flex-direction: column;
  background-color: white;

  .text {
    &Header {
      padding-top: 40px;
      color: $medium-gray;
    }
  }
}

.modalCloseButton {
  position: absolute;
  text-transform: uppercase;
  color: #5e5e5e;
  font-size: 8px;
  font-weight: 100;
  letter-spacing: 2.4px;
  cursor: pointer;

  top: 10px;
  right: 10px;
}

@include small {
  :global(.MuiModal-root) {
    // overflow-y: scroll;
  }

  .modalWrapper {
    margin: 0;
    width: fit-content;
    padding-top: 75px;
    max-height: 100%;
    height: fit-content;
    overflow-y: auto;
  }

  .modalCloseButtonProduct {
    position: absolute;
    text-transform: uppercase;
    color: #5e5e5e;
    font-size: 8px;
    font-weight: 100;
    letter-spacing: 2.4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;

    top: 25px;
    right: 0px;
    left: 15px;

    height: 60px;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    color: #5e5e5e;
  }

  .modalCloseButton {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2em;
  }

  .productDetailHeader {
    overflow: visible;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;

    span {
      text-align: center;
    }

    .placeIcon {
      position: absolute;
      left: 10px;
    }
  }

  .filterMenuBoxShadow {
    box-shadow: 0px 4px 4.7px rgba(0, 0, 0, 0.3);
    left: 0;
    top: 0;
  }
}
