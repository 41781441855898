@import 'styles/colors';
@import 'styles/mixins';

.storesContainer {
    display: flex;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    @include small{
     //flex-direction: column; 
      flex-wrap: wrap;
    }
  }
  
  .text {
    &ShopType {
      margin-top: auto !important;
    }
  
    &Redirect {
      cursor: pointer;
      text-decoration-color: $gray;
    }
  
    &ComingSoon {
      font-weight: bold;
      visibility: hidden;
      position: absolute;
      text-transform: uppercase;
      left: 22px;
    }
  }
  
  .storeContainer {
    border: 1px solid #EDEDED;
    border-radius: 2px;
    background: #F7F7F7;
    margin-right: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.10);
    cursor: pointer;
  
    img {
      padding: 30px;
      align-self: center;
      max-width: 125px;
      margin-top: auto !important;
    }
  
    @include small{
      width: 44%;
      margin: 5px;
     }
  
  
  }
  
  .storeContainerActive:hover {
    background: $blue;
    
    .text {
      &ShopType {
        color: $white;
      }
    }
  }
  
  .storeContainerInactive:hover {
    background: #5E5E5E;
    opacity: 0.73;
  
    cursor: default;
  
    img {
      opacity: 0.1;
    }
  
    .text {
      &ComingSoon {
        visibility:visible;
      }
  
      &ShopType {
        color: $medium-gray;
      }
    }
  }
  
  .noticeContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  
  .loading{
    padding: 75px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }