

.dunning{
    .close__button{
        display: none;
    }
}

.loading{
    margin-top:40px;
    margin-bottom: 40px;
}

.downgradeContainer{
    max-width: 600px;
    margin: 0 auto;
    margin-top: 50px;
    label{
        text-align: left;
    }
}