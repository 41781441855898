@import "styles/colors.scss";

.card {
  padding: 40px;
  display: flex;
  flex-direction: column;
  border-radius: 0px;
  background-color: $lighter-gray;
  border: 0.5px solid #979797;
}

.upsellContainer {
  background-image: url("/assets/images/checkout-upsell-background.png");
  background-size: cover;
  padding: 50px;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
  margin-top: 25px;
  ul li {
    color: $white;
  }
}
