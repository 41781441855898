.App {
  .toast-success-text {
    color: $white;
    margin-right: 5px;
    text-align: left;
  }
  .toast-content-text {
    margin-right: 0px;
  }

  .toast-link {
    //border-bottom: 1px solid #FFF;
    color: #fff !important;
  }

  .Toastify__toast-icon {
    display: none;
  }

  .Toastify__toast {
    color: $white;
  }

  .Toastify__close-button {
    color: $white;
  }

  .Toastify__toast-container {
    position: fixed;
    width: calc(100% - 240px);
    right: 0px;
    left: initial;
    // height: 100%;
    font-family: "Nunito Sans", sans-serif !important;
    height: auto;
    transform: translateX(0%);
    top: 0px;
    padding: 0px;
    color: $white;
    z-index: 9999;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: $white;
    }

    .notifications__close-button {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      font-size: 10px;
      font-style: normal;
      text-transform: uppercase;
      text-transform: uppercase;

      font-size: 8px;
      font-weight: 100;
      letter-spacing: 2.4px;
      color: #fff;
      cursor: pointer;

      svg {
        width: 14px;
        margin-right: 3px;
        margin-top: -5px;
      }
    }
  }

  .toast-success-text {
    font-weight: bold;
  }

  .toast-error-title {
    letter-spacing: 6px !important;
    font-weight: bold;
  }

  .toast-error-text {
    //padding-left: 20px;
    font-family: "Nunito Sans", sans-serif !important;
    letter-spacing: 6px !important;
    //font-weight: bold;
  }

  .Toastify__toast-container--top-left {
  }
  .Toastify__toast-container--top-center {
  }
  .Toastify__toast-container--top-right {
  }
  .Toastify__toast-container--bottom-left {
  }
  .Toastify__toast-container--bottom-center {
  }
  .Toastify__toast-container--bottom-right {
  }

  /** Classes for the displayed toast **/
  .Toastify__toast {
  }
  .Toastify__toast--rtl {
  }
  .Toastify__toast--dark {
  }
  .Toastify__toast--default {
  }
  .Toastify__toast--info {
  }
  .Toastify__toast--success {
    background-color: $blue;
    border-radius: 0px;
    margin-left: -19px;
    text-align: left;
    font-family: "Nunito Sans", sans-serif !important;
    font-size: 12px;
    .Toastify__toast-body {
      text-align: left;
      padding-left: 50px;
    }
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--error {
    background-color: $peach;
    top: 35%;
    left: 25%;

    z-index: 9;
    //transform: translateY(50%) !important;
    //margin-top: 36vh;
    max-width: 400px;
    max-height: 400px;
    min-height: 300px;
    min-width: 400px;
    border: 2px dashed $white;
    text-align: center;

    background-image: url("/assets/images/icon-error.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 90%;

    text-align: left;
    position: fixed;
    top: calc(50vh - 200px);
    left: calc(50vw - 450px);
    padding-left: 25px;
    padding-right: 15px;

    .notifications__close-button {
      position: absolute;
      top: 5px;
      right: 5px;
    }

    .button__link {
      text-decoration: none;
    }
  }
  .Toastify__toast-body {
  }

  /** Classes for the close button. Better use your own closeButton **/
  .Toastify__close-button {
  }
  .Toastify__close-button--default {
  }
  .Toastify__close-button > svg {
  }
  .Toastify__close-button:hover,
  .Toastify__close-button:focus {
  }

  /** Classes for the progress bar **/
  .Toastify__progress-bar {
  }
  .Toastify__progress-bar--animated {
  }
  .Toastify__progress-bar--controlled {
  }
  .Toastify__progress-bar--rtl {
  }
  .Toastify__progress-bar--default {
  }
  .Toastify__progress-bar--dark {
  }
}

@include small {
  .App {
    .Toastify__toast-container {
      left: 0px;
      width: 100%;
    }
    .Toastify__toast--error {
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
    }
  }
}
