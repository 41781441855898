@import "styles/colors.scss";
@import "styles/mixins";

.plans {
  display: flex;
  margin-top: 40px;
  margin-bottom: 25px;
}

.loading {
  margin-top: 25px;
}

.tryFreeContainer {
  display: flex;
  justify-content: center;
  align-items: baseline;

  li span {
    position: relative;
    left: -10px;
  }

  p {
    margin-right: 5px;
  }
}

.getStartedButton {
  margin-top: 5px;
  font-size: 14px !important;
  //letter-spacing: normal;

  &:hover {
    text-decoration: none !important;
  }
}

.planCardsContainer {
  padding-top: 30px;
  padding-bottom: 36px;
  display: flex;
  flex-direction: row;
}

@include small {
  .plans {
    padding-top: 80px;
  }
}
