.App .profile-billing-template {
  .MuiPaper-root {
    background: #fcfcfc;
  }

  .root {
    width: 100%;
  }

  #payment-element {
    border: 0.5px solid #979797;
    background: #fcfcfc;
    padding: 20px;
  }

  .paper {
    width: 100%;
    margin-bottom: 16px;
  }

  .header {
    border-bottom: 1px solid $gray;
  }

  .card {
    display: flex;
    padding: 20px;
    flex-direction: column !important;
  }

  .cc-display {
    padding: 10px 25px;
    border: 1px solid $gray;

    letter-spacing: 0.2em;
    color: $gray;
    width: 350px;
  }

  .button {
    margin-top: 20px;
  }

  .text {
    &__payment-header {
      font-size: 24px;
      color: $medium-gray;
    }

    &__card-title {
      font-size: 12px;
      padding-top: 5px;
      letter-spacing: 0.2em;
      padding-bottom: 5px;
    }
  }

  .credit-card-stars {
    margin: 0px;
    span{
      vertical-align: sub;
    }
  }

  .poweredByStripe {
    width: 120px;
  }
}
