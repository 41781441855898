@import 'styles/mixins';
@import 'styles/colors';

.onboardingStepTwo {
  position: relative;

  .planCardsContainer {
    padding-top: 30px;
    padding-bottom: 36px;
    display: flex;
    justify-content: center;

    @include medium-down {
      flex-direction: column;
    }

    @include small {
      padding: 20px 0 60px 0;
      flex-direction: column;
      background-image: url('/assets/images/onboarding_background.jpg');
      align-items: center;
      justify-content: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 16px -50px;      
    }
  }
}

.tryFreeContainer {
  display: flex;
  justify-content: center;
  align-items: baseline;

  li span {
    position: relative;
    left: -10px;
  }

  p {
    margin-right: 5px;
  }
}

.getStartedButton {
  font-size: 12px !important;
  margin-top: 5px;

  div {
    display: flex;
  }
}

.termsConditionsContainer {
  background-color: $white;
  max-width: 200;
  padding: 40px;
  max-width: 350px;
  text-align: center;
}