@import "styles/colors.scss";

.card {
  padding: 40px;
  background-color: $lighter-gray;
}

.overide {
  text-decoration: none;
}
